import React from 'react'
import Icon from './Icon'

export const title = 'Gear Icon'

export default function GearIcon ({ className, color = '#000000' }) {
  const style = className ? {} : { width: '18px', height: '18px' }
  return <Icon
    color={color}
    title={title}
    className={className}
    style={style}
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M6.45 1.45c.13-.52.61-.87 1.14-.87h1.5c.53 0 1.01.36 1.14.9l.39.87v.01a.56.56 0 00.74.31l.93-.37a1.2 1.2 0 011.42.2l1.05 1.05c.37.36.48.96.2 1.45l-.37.9a.63.63 0 000 .44l.03.07v.01c.07.1.15.18.25.22l.91.39c.54.12.9.6.9 1.14v1.5c0 .53-.36 1.01-.9 1.14l-.88.39a.56.56 0 00-.31.73l.37.94a1.2 1.2 0 01-.2 1.42l-1.05 1.05c-.39.39-.99.48-1.46.2l-.9-.37a.6.6 0 00-.73.31l-.4.9c-.12.52-.6.88-1.13.88h-1.5c-.54 0-1.02-.36-1.14-.9l-.39-.88a.56.56 0 00-.74-.31l-.93.37a1.2 1.2 0 01-1.42-.2L1.92 14.3c-.4-.4-.48-.97-.2-1.45l.37-.9a.6.6 0 00-.32-.74l-.9-.4A1.17 1.17 0 010 9.68v-1.5c0-.54.36-1.02.9-1.14l.87-.39h.01a.56.56 0 00.31-.74l-.37-.93a1.2 1.2 0 01.2-1.42l1.05-1.06c.39-.38.99-.48 1.45-.18l.9.36a.6.6 0 00.74-.32l.39-.9zm1.14-.03c-.17 0-.3.12-.33.24v.05l-.03.04-.4.94c-.32.7-1.1 1.02-1.8.77h-.01v-.01l-.95-.38-.04-.02-.04-.02c-.11-.08-.29-.08-.43.06L2.51 4.14c-.11.11-.13.3-.05.43l.02.03.01.03.38.96a1.4 1.4 0 01-.76 1.82l-.92.4-.04.02-.05.01a.33.33 0 00-.26.33v1.5c0 .17.12.3.24.33h.05l.04.02.94.4c.7.32 1.02 1.12.77 1.81l-.01.02-.38.94-.02.03-.02.04c-.09.14-.07.3.06.44l1.05 1.05c.11.1.3.13.43.05l.03-.02.03-.01.96-.38a1.4 1.4 0 011.82.76l.4.91.02.05.01.04c.03.16.16.27.33.27h1.5c.17 0 .3-.12.33-.25v-.04l.02-.04.4-.94v-.01c.33-.7 1.12-1.01 1.81-.76h.02l.94.39.04.01.04.03c.1.08.29.08.43-.06l1.05-1.05c.1-.11.13-.3.05-.43l-.02-.03-.01-.03-.38-.97a1.4 1.4 0 01.76-1.82l.91-.4.05-.02h.04a.33.33 0 00.27-.33v-1.5c0-.17-.11-.3-.27-.33h-.04l-.04-.03-.94-.4c-.33-.13-.58-.39-.73-.69l-.04-.09v-.07c-.1-.32-.1-.66.03-.97l.38-.94.01-.04.02-.03a.36.36 0 00-.05-.44L13.12 3.1a.36.36 0 00-.43-.05l-.03.02-.03.01-.97.38a1.4 1.4 0 01-1.82-.76l-.4-.92-.02-.04v-.05a.33.33 0 00-.33-.26h-1.5zm.74 4.66a2.83 2.83 0 100 5.66 2.83 2.83 0 000-5.66zM4.66 8.9a3.67 3.67 0 117.34 0 3.67 3.67 0 01-7.34 0z' />
  </Icon>
}
