import React from 'react'
import Icon from './Icon'

export const title = 'HoloFuel Icon'

export default function HoloFuelIcon ({ className, color = '#000000' }) {
  return <Icon
    color={color}
    title={title}
    className={className}
  >
    <path d='M2.25 21.21c.32 0 .66.02.99 0a9.23 9.23 0 007.34-4.62c.33-.61.58-1.28.77-1.95.01-.05.01-.11.03-.16H8.96a6.8 6.8 0 01-1.64-.2l-.06-.05.39-.94.94-2.22c.03-.11.07-.2.13-.3.07-.06.16-.1.25-.1h2.45l-.06-.33a8.2 8.2 0 00-1.42-2.93c-.32-.43-.68-.82-1.06-1.2-.48-.46-1-.85-1.59-1.18A9.82 9.82 0 003.74 3.8c-.44-.05-.87-.07-1.32-.1-.1 0-.22-.01-.32-.03a.14.14 0 01-.1-.1L1.54 2.5c-.18-.42-.35-.85-.54-1.26L.55.2.88.1C1.56.02 2.25-.01 2.94.02a14.5 14.5 0 015.28 1.2 13.47 13.47 0 013.62 2.3l.46.41a13.4 13.4 0 012.02 2.56c.57.93.99 1.94 1.26 3 .1.34.16.7.24 1.05.01.05.06.1.12.1h3.13c.06-.25.1-.5.15-.76a12.38 12.38 0 011.65-3.75 17.08 17.08 0 011.91-2.27A14.07 14.07 0 0131.54.1c.24-.02.48-.04.72-.04h1.08c.36.02.71.1 1.06.16.02 0 .02.02.05.05l-.26.63-.85 2c-.1.24-.21.48-.3.73-.07.16-.22.1-.33.13a10.85 10.85 0 00-4.8 1.09c-.67.33-1.29.74-1.86 1.23-.23.2-.5.4-.7.64a8.67 8.67 0 00-2.17 3.77c-.01.05-.01.11-.03.16h.17c.8 0 1.62-.02 2.42 0 .48 0 .96.08 1.42.24l.05.03-.45 1.04-.94 2.24c-.1.22-.16.27-.4.27h-2.25c.06.22.1.4.17.6a7.79 7.79 0 001.65 2.85 9.06 9.06 0 002.4 1.99 10 10 0 003.8 1.28l1.21.1c.16 0 .3 0 .46.02.03.02.07.04.08.07.26.63.52 1.26.8 1.89l.66 1.55c-.21.04-.41.1-.63.1-.52.04-1.06.07-1.58.07-.48 0-.96-.05-1.42-.11-.63-.1-1.28-.2-1.91-.35a14.2 14.2 0 01-4.66-2.14c-.4-.3-.79-.62-1.17-.93a12.74 12.74 0 01-3.08-4.15c-.36-.76-.6-1.56-.77-2.38-.04-.08-.05-.18-.07-.26 0-.15-.07-.19-.21-.19h-3.12a11.84 11.84 0 01-3.66 6.68c-.48.42-1 .82-1.5 1.21a13.56 13.56 0 01-5.95 2.38c-.48.08-.96.13-1.43.18h-.82a29 29 0 01-1.19-.03 3.76 3.76 0 01-.41-.07c-.08-.01-.11-.05-.06-.14.3-.73.63-1.45.94-2.18l.55-1.3z' />
  </Icon>
}
