import React from 'react'
import Icon from './Icon'

export const title = 'Laptop Icon'

export default function LaptopIcon ({ className, color = '#000000' }) {
  return <Icon
    color={color}
    title={title}
    className={className}
  >
    <path d='M7.48 0C3.01 0 0 2.58 0 6.39c0 5.06 6.44 14.2 6.5 14.27.25.27.62.43 1 .43s.73-.16 1-.43c.06-.07 6.5-9.21 6.5-14.27C14.97 2.56 11.95 0 7.48 0zm0 12.4a4.93 4.93 0 11.02-9.86 4.93 4.93 0 01-.02 9.87z' fill='#00818D' />
    <path d='M10.75 9.6l-.08.13a3.9 3.9 0 01-6.4 0c-.04-.04-.06-.09-.1-.12 0-.02 0-.04.02-.07.04-.23.18-.4.39-.5.66-.34 2.08-.8 2.08-.8v-.48l-.03-.03c-.28-.21-.47-.53-.52-.87V6.8h-.04a.52.52 0 01-.48-.33.51.51 0 01-.07-.28c0-.06.01-.13.03-.19a.36.36 0 01.1-.17l.14-.09-.03-.14c-.23-1.02.52-1.93 1.58-2H7.64c1.04.07 1.8 1 1.58 2l-.03.14.14.09c.05.03.08.1.1.17.02.07.04.13.04.2 0 .1-.04.19-.07.27a.52.52 0 01-.49.33h-.04l-.01.06c-.05.34-.25.66-.52.87l-.04.03v.47s1.43.49 2.09.8c.19.1.33.3.38.5-.02.02-.02.04-.02.08z' fill='#00818D' />
  </Icon>
}
